import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageEndBanner from "../components/PageEndBanner"
import TwoColumnProduct from "../components/TwoColumnProduct"

const CabinPost = ({
  pageContext,
  data: {
    cabinPost: { seo, cabins },
    contactPage,
  },
}) => {
  return (
    <Layout
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      {cabins.map((cab, index) => (
        <TwoColumnProduct
          key={cab.id}
          title={cab.cabinName}
          heroimage={cab.cabinImage.gatsbyImageData}
          reverse={index % 2 ? true : false}
          isDark={index % 2 ? false : true}
          description={cab.description}
          structuredData={cab.description.value}
        />
      ))}

      <PageEndBanner recordId={contactPage.id} />
    </Layout>
  )
}

export default CabinPost

export const query = graphql`
  query CabinPostQuery($id: String, $locale: String) {
    cabinPost: datoCmsCabinPost(
      originalId: { eq: $id }
      locale: { eq: $locale }
    ) {
      id
      title
      cabins: cabin {
        id
        cabinName
        cabinImage {
          gatsbyImageData(height: 350)
        }
        description {
          value
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }

    contactPage: datoCmsContactPage(locale: { eq: $locale }) {
      id: originalId
    }
  }
`
